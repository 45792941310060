.burger-button {
  position: fixed;
  top: 80px;
  left: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar {
  text-align: left;
  height: 100%;
  width: 250px;
  position: fixed;
  top: 70px;
  left: -250px;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 40px;
  border-right: 1px solid #edf2f7;
  z-index: 1;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #202020;
  font-weight: 500;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #818181;
}

.sidebar li {
  position: relative;
  transition: background-color 0.3s ease;
}

.sidebar li.selected {
  background-color: #f4ebfb;
}

.sidebar li.selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  max-height: 40px;
  background-color: #7000c8;
  transition:
    top 0.3s ease,
    height 0.3s ease;
}

.link-text-image {
  display: flex;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 36px;
  background: none;
  border: none;
  color: #818181;
  cursor: pointer;
}

.close-button:hover {
  color: #f1f1f1;
}

.menu-item {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 8px 8px 8px 32px;
  align-items: center;
  color: #202020;
  font-weight: 500;
}

.menu-item:hover {
  color: #818181;
}

.sidebar li.sub-item-selected {
  background-color: #f4ebfb;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(-180deg);
}
