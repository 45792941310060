/* .custom-logo-modal .modal-dialog {
  max-width: 80%; 
  width: 80%;
} */

.dropzone-logo-custom-box {
  /*   background-color: #ffffff;
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  padding: 20px; */
  height: 300px;
}

.dropzone-logo-custom-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-logo-text-box {
  background-color: #fcfcfc;
  border: 2px solid #efefef;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
}

.logo-wrapper {
  position: relative;
  flex-shrink: 0;
}

.logo-preview {
  width: 275px;
  height: 275px;
  border-radius: 50%;
  object-fit: cover;
}

.logo-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  padding: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  animation: spin 1s linear infinite;
}

.logo-upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  padding: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
