.dropzone-custom-container {
  color: #004159;
  margin-bottom: 15px;
  width: 100%;
}

.dropzone-custom-label {
  margin-bottom: 5px;
  font-weight: 600;
}

.dropzone-custom-box {
  background-color: #ffffff;
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  padding: 20px;
  height: 200px;
}

.dropzone-custom-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: visible;
  overflow-y: hidden;
}

.dropzone-custom-text-box {
  background-color: #fcfcfc;
  border: 2px solid #efefef;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
}

.image-gallery {
  display: flex;
  overflow-x: auto;
}

.image-wrapper {
  position: relative;
  flex-shrink: 0;
}

.image-preview {
  width: 140px;
  height: 140px;
  border-radius: 14%;
  object-fit: cover;
}

.loading-spinner {
  position: absolute;
  top: 18%;
  left: 75%;
  margin-top: -12px;
  margin-left: -12px;
  padding: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  animation: spin 1s linear infinite;
}

.delete-icon {
  position: absolute;
  top: 18%;
  left: 75%;
  margin-top: -12px;
  margin-left: -12px;
  padding: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

.image-placeholder {
  width: 140px;
  height: 140px;
  cursor: pointer;
  border: 2px dashed #8e8e8e;
  border-radius: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
