.title {
  font-weight: 600;
  font-size: 36px;
  color: #202020;
  margin-bottom: 10px;
}

.title-image {
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.sub-title {
  margin-bottom: 40px;
}
