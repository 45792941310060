.input-container {
  color: #004159;
  margin-bottom: 14px;
  padding: 1px;
  width: 100%;
}

.input-label {
  margin-bottom: 5px;
  font-weight: 600;
}

.input-box {
  border: 1px solid #ebf2fa;
  border-radius: 6px;
  height: 40px;
  padding: 10px;
  width: 100%;
}

.input-box::placeholder {
  color: #b5b5bd;
}

.input-box:focus {
  border: 1px solid #f4ebfb;
  outline-offset: 0px;
  outline: none;
  box-shadow: 0 0 0 1px #f4ebfb;
}

.input-box-title {
  border: 1px solid #f7f9fb;
  background-color: #f7f9fb;
  border-radius: 6px;
  padding: 0px;
  width: 100%;
  font-weight: 600;
  font-size: 36px;
  color: #202020;
}

.input-box-title::placeholder {
  color: #b5b5bd;
}

.input-box-title:hover {
  border: 1px solid #f4ebfb;
  outline-offset: 0px;
  outline: none;
  box-shadow: 0 0 0 1px #f4ebfb;
}

.input-box-title:focus {
  border: 1px solid #f4ebfb;
  outline-offset: 0px;
  outline: none;
  box-shadow: 0 0 0 1px #f4ebfb;
}
