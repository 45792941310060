.tilebox-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.tilebox-skeleton-container {
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .tilebox-container {
    text-align: center;
  }
}
