.splash-screen-container .splash-screen-circle-tick {
  width: 100px;
  height: 100px;
  border: 3px solid #000;
  border-right-color: transparent;
  border-radius: 50%;
  animation: splash-circle-tick-animation 2s linear infinite;
  margin: auto;
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .splash-screen-container {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .splash-screen-container .splash-screen-circle-tick {
    margin: 0;
    margin-right: 2rem;
  }
}

@keyframes splash-circle-tick-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
