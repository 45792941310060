.company-layout-container {
  display: grid;
  height: 100%;
  grid-template-columns: 250px 1fr;
  grid-template-areas: 'sidebar main';
  overflow: hidden;
}

.sidebar {
  grid-area: sidebar;
  overflow-y: auto;
  background-color: #ffffff;
}

.company-main-content {
  grid-area: main;
  overflow-y: auto;
  background-color: #f7f9fb;
}

@media (max-width: 768px) {
  .company-layout-container {
    grid-template-columns: 0px 1fr;
  }
}
