.App {
  text-align: left;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
